<template>
    <div class="o365-header-row o365-new-record" v-bind="$attrs" :data-o365-rowindex="row.index"
        :class="{'active': row.current, dirty: row.hasChanges}">
        <template v-for="col in columns" :key="col.order">
            <template v-if="!col.hide">
                <NewRecordCell :row="row" :col="col" 
                    :style="{'height': gridControl.props.rowHeight+'px'}"
                    :selectionClass="gridControl.navigation?.getSelectionClass(col.order, row.index)"/>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
export interface INewRecordProps {
    row: any;
    columns: any[];
}
</script>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import NewRecordCell from 'o365.vue.components.NewRecordCell.vue';

const props = defineProps<INewRecordProps>();

const gridControl = useBaseGridControl();

function selectionClass(colIndex: number) {
    let className = '';
    const classMap = gridControl.value.gridSelectionInterface?.selectionClassMap?.[props.row?.index]?.[colIndex];
    if (classMap) {
        className = classMap.join(' ');
    }
    const activeCell = gridControl.value.gridFocusControl?.activeCell;
    if (activeCell && activeCell === `${colIndex}-${props.row.index}`) {
        className = (className ? className + ' ' : '') + 'o365-focus-cell';
    }

    return className;
}
</script>